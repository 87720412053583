
import { ref, toRefs } from 'vue'

export default {
  props: {
    selected: {
      default: () => 0
    },
  },
  emits: ['selectSearchOption'],
  setup(props: any, { emit } : any) {
    const {
      selected
    } = toRefs(props)
    const options = ref([
      {
        name: 'Id Transaksi',
        val: 0
      },
    ])

    const selectOptionAct = (evt: any, val: any) => {
      console.log('val', [evt, val])
      emit('selectSearchOption', evt, val)
    }

    return {
      selectOptionAct,
      selected,
      options
    }
  }
}

