import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
      return (_openBlock(), _createElementBlock("div", { key: item }, [
        _createElementVNode("p", {
          class: _normalizeClass(item.val === $setup.selected ? 'text-sm font-normal text-primary cursor-pointer' : 'text-sm text-grey cursor-pointer'),
          onClick: ($event: any) => ($setup.selectOptionAct($event, item))
        }, _toDisplayString(item.name), 11, _hoisted_1)
      ]))
    }), 128))
  ]))
}