
import InputText from 'primevue/inputtext'
import { ref, toRefs } from 'vue'

export default {
  components: {
    InputText,
  },
  props: {
    selectedStatusLabel: {
      default: () => 'Semua Status'
    }
  },
  emits: ['showDropDown', 'onSearch'],
  setup(props: any, { emit } : any) {
    const placeholder = ref('Cari Disini...')
    const inputSearch = ref('')
    const canClick = ref(true)
    const {
      selectedStatusLabel
    } = toRefs(props)
    const showDropDown = (evt: any, val: any) => {
      console.log('xxx')
      emit('showDropDown', evt, val)
    }

    const onSearch = () => {
      console.log('onSearch', inputSearch.value)
      emit('onSearch', inputSearch.value)
    }

    return {
      placeholder,
      inputSearch,
      canClick,
      showDropDown,
      selectedStatusLabel,
      onSearch
    }
  }
}

