
import { ref, toRefs } from 'vue'

export default {
  props: {
    selected: {
      default: () => 0
    },
  },
  emits: ['selectStatusApproval'],
  setup(props: any, { emit } : any) {
    const {
      selected
    } = toRefs(props)
    const options = ref([
      {
        name: 'Semua Status',
        val: 0
      },
      {
        name: 'Approved',
        val: 1
      },
      {
        name: 'Not-Approved',
        val: 2
      }
    ])

    const selectStatusAct = (evt: any, val: any) => {
      console.log('val', [evt, val])
      emit('selectStatusApproval', evt, val)
    }

    return {
      selectStatusAct,
      selected,
      options
    }
  }
}

