
import { onMounted, ref, toRefs } from 'vue'
import { DatePicker } from 'v-calendar'
import moment from 'moment'
import Button from 'primevue/button'
import { isNull, isUndefined } from 'lodash'

export default {
  components: {
    DatePicker,
    Button
  },
  props: {
    selected: {
      default: () => 0
    },
    rangeDate: {
      default: () => null
    }
  },
  emits: ['onFilterDateRange'],
  setup(props: any, { emit } : any) {
    const {
      selected,
      rangeDate
    } = toRefs(props)

    const rangeDateOrigin = rangeDate.value
    // const rangeDate = ref({
    //   start: moment().toDate(),
    //   end: moment().add(7, 'day').toDate(),
    // })

    const applyFilterDateRange = (evt: any) => {
      rangeDateOrigin.value = isUndefined(rangeDateOrigin.value) ? rangeDate.value : rangeDateOrigin.value
      console.log(isUndefined(rangeDateOrigin.value))
      console.log('val', rangeDateOrigin.value)
      emit('onFilterDateRange', evt, rangeDateOrigin.value)
    }

    const resetFilterDateRange = (evt: any) => {
      rangeDateOrigin.value = {
        // start: moment().toDate(),
        // end: moment().add(7, 'day').toDate(),
        start: null,
        end: null
      }
      console.log('val', rangeDateOrigin.value)
      emit('onFilterDateRange', evt, rangeDateOrigin.value)
    }

    const onChangeDate = (val: any) => {
      if (!isNull(val)) {
        console.log('update', val)
        rangeDateOrigin.value = val
        console.log('updatevalue', rangeDateOrigin.value)
      } else {
        rangeDateOrigin.value = rangeDate.value
      }
    }

    onMounted(() => {
      if (rangeDateOrigin.value === null) {
        rangeDateOrigin.value = {
          // start: moment().toDate(),
          // end: moment().add(7, 'day').toDate(),
          start: moment().startOf('month').toDate(),
          end: moment().endOf('month').toDate()
        }
      }
    })

    return {
      applyFilterDateRange,
      resetFilterDateRange,
      selected,
      rangeDateOrigin,
      onChangeDate
    }
  }
}

