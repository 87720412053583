
import Dialog from 'primevue/dialog'
import Card from 'primevue/card'
import Button from 'primevue/button'
import moment from 'moment'

import {
  defineComponent,
  computed,
  reactive,
  ref,
  toRefs,
  onMounted
} from 'vue'
import apiUseCase from '@/usecase/apiUseCase'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { IDeposit } from '@/utils/interfaces/iDeposit'
import currencyNumberFormat from '@/utils/helpers/rupiahFormat'

export default defineComponent({
  name: 'ModalDefault',
  components: {
    Dialog,
    Card,
    Button
  },
  props: {
    show: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: String,
      default: ''
    }
  },
  emits: ['hideApproveDialog', 'submit', 'currencyNumberFormat'],
  setup(props, { emit }: any) {
    const { show, id } = toRefs(props)
    const store = useStore()
    const router = useRouter()
    const dataSource = ref<IDeposit>()
    const endpoint = '/transaction/v1/TransactionPp/Deposit'
    const endpointLatestExpensesRevision = 'transaction/v1/RitExpenseRevision/Latest-Revision'
    const isLoading = ref(true)
    const expensetemp = ref(0)

    const showModal = show.value

    const getDetail = async () => {
      const {
        result,
        error
      } = await apiUseCase.get(`${endpoint}/${id.value}`)
      if (!error) {
        const resultMap: IDeposit = result
        dataSource.value = resultMap
        // eslint-disable-next-line array-callback-return
        dataSource.value.Rits.map((x) => {
          expensetemp.value += x.RitExpenseTotalAmount
        })
        isLoading.value = false
      }
      store.dispatch('hideLoading')
    }

    // const getLatestExpenseRevision = async () => {
    //   await apiUseCase.get(`${endpointLatestExpensesRevision}/${activeRitId.value}`).then((response) => {
    //     // console.log('last', response.result.Items.length)
    //     latestExpenseRevisionTemp.value = response.result
    //     // console.log('LatestExpenseRevisionTemp', latestExpenseRevisionTemp.value.Items?.length > 0)
    //     // console.log('detailRitxxx', dataRitDetail.value)

    //     if (latestExpenseRevisionTemp.value.Items?.length > 0) {
    //       // console.log('zzzz')
    //       latestRevisionDate.value = latestExpenseRevisionTemp.value.UpdatedAt
    //       latestExpenseRevision.value = latestExpenseRevisionTemp.value.Items.map((x: any) => ({
    //         ExpenseName: x.ExpenseName,
    //         Nominal: x.RevisiAmount
    //       }))
    //       grandtotalExpense.value = latestExpenseRevisionTemp.value.TotalRevisiAmount
    //     } else {
    //       // console.log('cccc')
    //       latestExpenseRevision.value = dataRitDetail.value.RitExpenses.map((x: any) => ({
    //         ExpenseName: x.ExpenseName,
    //         Nominal: x.Nominal
    //       }))
    //       countOutcomeTotal()
    //     }
    //     console.log('LatestExpenseRevision', latestExpenseRevision.value)
    //   })
    // }

    const closeDialog = () => {
      emit('hideApproveDialog')
    }

    const submit = () => {
      console.log('id', id.value)
      emit('submit', id.value)
    }

    const goDetail = (item: any) => {
      const token = localStorage.getItem('ut')
      const routeData = router.resolve({ name: 'reports-detail', params: { typeReport: `${item.Report.Query}?TransactionPpId=${item.Id}&Token=${token}` } })
      window.open(routeData.href, '_blank')
    }

    onMounted(() => {
      console.log('show', show.value)
      getDetail()
    })

    return {
      dataSource,
      showModal,
      closeDialog,
      submit,
      isLoading,
      moment,
      currencyNumberFormat,
      expensetemp,
      goDetail
    }
  }
})
